$(document).ready(function() {
    // Check for click events on the navbar burger icon
    // toggles is-active and aria-expanded

    function weClick(event){
        if(event.type === 'click'){
            return true;
        }
        else if(event.type === 'keypress'){
            var code = event.charCode || event.keyCode;
            if((code === 32)|| (code === 13)){
                return true;
            }
        }
        else{
            return false;
        }
    }

    $(".navbar-burger").on('click keypress', function(event){
        if(weClick(event) === true){
            var burgerButton = $(event.currentTarget);
            $(".navbar-burger").toggleClass("is-active");
            $(".navbar-menu").toggleClass("is-active");

            if(burgerButton.attr('aria-expanded') === 'true') {
                $(this).attr('aria-expanded', 'false');
            } else {
                $(this).attr('aria-expanded','true');
            }
        }
        event.preventDefault();
        return false;
    });
  });
